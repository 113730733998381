import keyBy from 'lodash.keyby';
import merge from 'lodash.merge';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_PAGED_USERS_REQUEST,
  FETCH_PAGED_USERS_SUCCESS,
  FETCH_PAGED_USERS_FAILURE,
  SET_USERS_FILTER,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  USER_PRESENCE_CHANGE,
  USER_UPDATE_NOTIFICATION,
  FETCH_USER_VACATIONS_DATA_REQUEST,
  FETCH_USER_VACATIONS_DATA_SUCCESS,
  FETCH_USER_VACATIONS_DATA_FAILURE,
  FETCH_USER_REGISTRY_DATA_SUCCESS,
  FETCH_USER_REGISTRY_DATA_REQUEST,
  FETCH_USER_REGISTRY_DATA_FAILURE,
  SAVE_USER_VACATIONS_DATA_REQUEST,
  SAVE_USER_VACATIONS_DATA_SUCCESS,
  SAVE_USER_VACATIONS_DATA_FAILURE,
  SAVE_USER_REGISTRY_DATA_REQUEST,
  SAVE_USER_REGISTRY_DATA_SUCCESS,
  SAVE_USER_REGISTRY_DATA_FAILURE,
  FETCH_USER_PUNCHINGS_DATA_REQUEST,
  FETCH_USER_PUNCHINGS_DATA_SUCCESS,
  FETCH_USER_PUNCHINGS_DATA_FAILURE,
  SAVE_USER_PUNCHINGS_DATA_SUCCESS,
  SAVE_USER_PUNCHINGS_DATA_REQUEST,
  SAVE_USER_PUNCHINGS_DATA_FAILURE,
  USER_STATUS_CHANGE_NOTIFICATION,
  FETCH_USER_SUPERVIEWERS_REQUEST,
  FETCH_USER_SUPERVIEWERS_SUCCESS,
  FETCH_USER_SUPERVIEWERS_FAILURE,
  SAVE_USER_SUPERVIEWERS_REQUEST,
  SAVE_USER_SUPERVIEWERS_SUCCESS,
  SAVE_USER_SUPERVIEWERS_FAILURE,
  FETCH_USER_GROUPS_SUCCESS,
} from './types';
import { PresenceEnums } from './PresenceUtils';
import User from '../lib/User';
import { AVATAR_TYPES } from '../files/FileUtils';
import { PUT_AVATAR_SUCCESS } from '../files/types';
import { RECEIVE_SET_DND_STATUS } from '../videocalls/types';
import { EDIT_PROTEL_NAME_SUCCESS, SAVE_HOTEL_PROTEL_SYNC_SUCCESS } from '../hotel/types';

const initialState = {
  fetchUsersLoaded: true,
  fetchUsersError: null,
  fetchUserLoaded: true,
  fetchUserError: null,
  saveUserLoaded: true,
  saveUserError: null,
  fetchUserVacationsDataLoaded: true,
  fetchUserVacationsDataError: null,
  saveUserVacationsDataLoaded: true,
  saveUserVacationsDataSuccess: false,
  saveUserVacationsDataError: null,
  fetchUserPunchingsDataLoaded: true,
  fetchUserPunchingsDataError: null,
  saveUserPunchingsDataLoaded: true,
  saveUserPunchingsDataSuccess: false,
  saveUserPunchingsDataError: null,
  fetchUserRegistryDataLoaded: true,
  fetchUserRegistryDataError: null,
  saveUserRegistryDataLoaded: true,
  saveUserRegistryDataSuccess: false,
  saveUserRegistryDataError: null,
  fetchUserSuperviewersLoaded: true,
  fetchUserSuperviewersError: null,
  saveUserSuperviewersLoaded: true,
  saveUserSuperviewersSuccess: false,
  saveUserSuperviewersError: null,
  byId: {},
  allIds: [],
  pages: {},
  currentPage: 0,
  total: 0,
  filter: {
    name: '',
  },
};

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_USERS_REQUEST:
    case FETCH_PAGED_USERS_REQUEST:
      return {
        ...state,
        fetchUsersLoaded: false,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS: {
      let { byId } = state;
      action.users.forEach((user) => {
        const { status, ...updatedUser } = user;
        byId = {
          ...byId,
          [user.id]: {
            ...updatedUser,
            webrtcExtensionNumber:
              user.others
                .filter((ext) => ext.type === 'WEBRTC')
                .map((ext) => ext.number)[0] || null,
            webrtcExtensionUsername:
              user.others
                .filter((ext) => ext.type === 'WEBRTC')
                .map((ext) => ext.username)[0] || null,
            status: {
              videocallDnd: false,
              yn: {
                WEB: state.byId[user.id]
                  ? state.byId[user.id].status.yn.WEB
                  : 0,
                MOBILE: state.byId[user.id]
                  ? state.byId[user.id].status.yn.MOBILE
                  : 0,
              },
              userStatus: user.status,
            },
          },
        };
      });
      return {
        ...state,
        byId,
        allIds: action.users.map((obj) => obj.id),
        fetchUsersLoaded: true,
        fetchUsersError: null,
      };
    }
    case FETCH_PAGED_USERS_SUCCESS: {
      const newById = keyBy(
        action.payload.users.map((user) => {
          const { status, ...updatedUser } = user;
          return {
            ...state.byId[user.id],
            ...updatedUser,
            status: {
              ...state.byId[user.id].status,
              userStatus: status,
            },
          };
        }),
        'id'
      );
      const ids = action.payload.users.map((obj) => obj.id);
      const pages = {
        ...state.pages,
        [action.payload.page]: ids,
      };
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById,
        },
        pages,
        currentPage: action.payload.page,
        total: action.payload.total,
        fetchUsersLoaded: true,
        fetchUsersError: null,
      };
    }
    case FETCH_USERS_FAILURE:
    case FETCH_PAGED_USERS_FAILURE:
      return {
        ...state,
        fetchUsersLoaded: true,
        fetchUsersError: action.errors,
      };

    case SET_USERS_FILTER:
      return {
        ...state,
        filter: {
          name: action.filter,
        },
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        fetchUserLoaded: false,
        fetchUserError: null,
      };
    case FETCH_USER_SUCCESS: {
      const { status, ...updatedUser } = action.user;
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.user.id]: {
            ...state.byId[action.user.id],
            ...updatedUser,
            status: {
              ...state.byId[action.user.id].status,
              userStatus: action.user.status,
            },
          },
        },
        fetchUserLoaded: true,
        fetchUserError: null,
      };
    }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetchUserLoaded: true,
        fetchUserError: action.errors,
      };
    case SAVE_USER_REQUEST:
      return {
        ...state,
        saveUserLoaded: false,
        saveUserError: null,
      };
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.user.id]: {
            ...state.byId[action.user.id],
            ...action.user,
          },
        },
        saveUserLoaded: true,
        saveUserError: null,
      };
    case SAVE_USER_FAILURE:
      return {
        ...state,
        saveUserLoaded: true,
        saveUserError: action.errors,
      };
    case USER_PRESENCE_CHANGE: {
      const { idUser, source, status } = action.payload;
      if (!state.byId[idUser]) {
        return state;
      }
      const currentStatus = state.byId[idUser].status.yn;
      let videocallDnd;
      if (status === PresenceEnums.Status.ONLINE) {
        currentStatus[source] += 1;
        ({ videocallDnd } = action.payload);
      } else {
        ({ videocallDnd } = state.byId[idUser].status);
      }
      if (
        status === PresenceEnums.Status.OFFLINE &&
        currentStatus[source] > 0
      ) {
        currentStatus[source] -= 1;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [idUser]: {
            ...state.byId[idUser],
            status: {
              ...state.byId[idUser].status,
              videocallDnd,
              yn: currentStatus,
            },
          },
        },
      };
    }
    case USER_UPDATE_NOTIFICATION: {
      const updatedFields = {};
      const data = action.payload;
      if (data.archived) {
        return state;
      }
      if (data.name) {
        updatedFields.name = data.name;
      }
      if (data.surname) {
        updatedFields.surname = data.surname;
      }
      if (data.department) {
        updatedFields.department = data.department;
      }
      if (data.name || data.surname || data.department) {
        updatedFields.departmentfullname = User.getFullname(
          data.name || state.byId[data.idEntity].name,
          data.surname || state.byId[data.idEntity].surname,
          data.department || state.byId[data.idEntity].department
        );
        if (data.name || data.surname) {
          updatedFields.fullname = User.getFullname(
            data.name || state.byId[data.idEntity].name,
            data.surname || state.byId[data.idEntity].surname
          );
        }
      }
      if (data.avatar) {
        updatedFields.avatar = data.avatar;
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [data.idEntity]: merge(state.byId[data.idEntity], updatedFields),
        },
      };
    }
    case USER_STATUS_CHANGE_NOTIFICATION: {
      if (!state.byId[action.payload.idEntity]) {
        return state;
      }
      const updatedFields = {};
      const data = action.payload;
      if (data.userStatus || data.userStatus === null) {
        updatedFields.status = {
          userStatus: data.userStatus,
        };
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [data.idEntity]: merge(state.byId[data.idEntity], updatedFields),
        },
      };
    }
    case RECEIVE_SET_DND_STATUS: {
      if (!state.byId[action.payload.user]) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.user]: {
            ...state.byId[action.payload.user],
            status: {
              ...state.byId[action.payload.user].status,
              videocallDnd: action.payload.enabled,
            },
          },
        },
      };
    }
    case FETCH_USER_VACATIONS_DATA_REQUEST:
      return {
        ...state,
        fetchUserVacationsDataLoaded: false,
        fetchUserVacationsDataError: null,
        saveUserVacationsDataSuccess: false,
      };
    case FETCH_USER_VACATIONS_DATA_SUCCESS:
      return {
        ...state,
        fetchUserVacationsDataLoaded: true,
        fetchUserVacationsDataError: null,
        byId: {
          ...state.byId,
          [action.data.userId]: {
            ...state.byId[action.data.userId],
            vacations: {
              validators: action.data.validators,
              toBeValidated: action.data.toBeValidated,
            },
          },
        },
      };
    case FETCH_USER_VACATIONS_DATA_FAILURE:
      return {
        ...state,
        fetchUserVacationsDataLoaded: true,
        fetchUserVacationsDataError: action.errors,
      };
    case FETCH_USER_PUNCHINGS_DATA_REQUEST:
      return {
        ...state,
        fetchUserPunchingsDataLoaded: false,
        fetchUserPunchingsDataError: null,
        saveUserPunchingsDataSuccess: false,
      };
    case FETCH_USER_PUNCHINGS_DATA_SUCCESS:
      return {
        ...state,
        fetchUserPunchingsDataLoaded: true,
        fetchUserPunchingsDataError: null,
        byId: {
          ...state.byId,
          [action.data.userId]: {
            ...state.byId[action.data.userId],
            punchings: {
              validators: action.data.validators,
              toBeValidated: action.data.toBeValidated,
              allowedIp: action.data.allowedIp,
              enabledPunching: action.data.enabledPunching,
            },
          },
        },
      };
    case FETCH_USER_PUNCHINGS_DATA_FAILURE:
      return {
        ...state,
        fetchUserPunchingDataLoaded: true,
        fetchUserPunchingDataError: action.errors,
      };
    case FETCH_USER_REGISTRY_DATA_REQUEST:
      return {
        ...state,
        fetchUserRegistryDataLoaded: false,
        fetchUserRegistryDataError: null,
      };
    case FETCH_USER_REGISTRY_DATA_SUCCESS:
      return {
        ...state,
        fetchUserRegistryDataLoaded: true,
        fetchUserRegistryDataError: null,
        byId: {
          ...state.byId,
          [action.userId]: {
            ...state.byId[action.userId],
            hr: {
              templateRegistry: action.data.templateRegistry,
              registries: action.data.registries,
            },
          },
        },
      };
    case FETCH_USER_REGISTRY_DATA_FAILURE:
      return {
        ...state,
        fetchUserRegistryDataLoaded: true,
        fetchUserRegistryDataError: action.errors,
      };
    case SAVE_USER_VACATIONS_DATA_REQUEST:
      return {
        ...state,
        saveUserVacationsDataLoaded: false,
        saveUserVacationsDataError: null,
        saveUserVacationsDataSuccess: false,
      };
    case SAVE_USER_VACATIONS_DATA_SUCCESS:
      return {
        ...state,
        saveUserVacationsDataLoaded: true,
        saveUserVacationsDataError: null,
        saveUserVacationsDataSuccess: true,
      };
    case SAVE_USER_VACATIONS_DATA_FAILURE:
      return {
        ...state,
        saveUserVacationsDataLoaded: true,
        saveUserVacationsDataError: action.errors,
        saveUserVacationsDataSuccess: false,
      };
    case SAVE_USER_PUNCHINGS_DATA_REQUEST:
      return {
        ...state,
        saveUserPunchingsDataLoaded: false,
        saveUserPunchingsDataError: null,
        saveUserPunchingsDataSuccess: false,
      };
    case SAVE_USER_PUNCHINGS_DATA_SUCCESS:
      return {
        ...state,
        saveUserPunchingsDataLoaded: true,
        saveUserPunchingsDataError: null,
        saveUserPunchingsDataSuccess: true,
      };
    case SAVE_USER_PUNCHINGS_DATA_FAILURE:
      return {
        ...state,
        saveUserPunchingsDataLoaded: true,
        saveUserPunchingsDataError: action.errors,
        saveUserPunchingsDataSuccess: false,
      };
    case SAVE_USER_REGISTRY_DATA_REQUEST:
      return {
        ...state,
        saveUserRegistryDataLoaded: false,
        saveUserRegistryDataSuccess: false,
        saveUserRegistryDataError: null,
      };
    case SAVE_USER_REGISTRY_DATA_SUCCESS:
      return {
        ...state,
        saveUserRegistryDataLoaded: true,
        saveUserRegistryDataSuccess: true,
        saveUserRegistryDataError: null,
      };
    case SAVE_USER_REGISTRY_DATA_FAILURE:
      return {
        ...state,
        saveUserRegistryDataLoaded: true,
        saveUserRegistryDataSuccess: false,
        saveUserRegistryDataError: action.errors,
      };
    case FETCH_USER_SUPERVIEWERS_REQUEST:
      return {
        ...state,
        fetchUserSuperviewersLoaded: false,
        fetchUserSuperviewersError: null,
        saveUserSuperviewersSuccess: false,
      };
    case FETCH_USER_SUPERVIEWERS_SUCCESS:
      return {
        ...state,
        fetchUserSuperviewersLoaded: true,
        fetchUserSuperviewersError: null,
        byId: {
          ...state.byId,
          [action.data.userId]: {
            ...state.byId[action.data.userId],
            calendar: {
              superviewers: action.data.superviewers,
              superview: action.data.superview,
            },
          },
        },
      };
    case FETCH_USER_SUPERVIEWERS_FAILURE:
      return {
        ...state,
        fetchUserSuperviewersLoaded: true,
        fetchUserSuperviewersError: action.errors,
      };
    case SAVE_USER_SUPERVIEWERS_REQUEST:
      return {
        ...state,
        saveUserSuperviewersLoaded: false,
        saveUserSuperviewersError: null,
        saveUserSuperviewersSuccess: false,
      };
    case SAVE_USER_SUPERVIEWERS_SUCCESS:
      return {
        ...state,
        saveUserSuperviewersLoaded: true,
        saveUserSuperviewersError: null,
        saveUserSuperviewersSuccess: true,
      };
    case SAVE_USER_SUPERVIEWERS_FAILURE:
      return {
        ...state,
        saveUserSuperviewersLoaded: true,
        saveUserSuperviewersError: action.errors,
        saveUserSuperviewersSuccess: false,
      };  

    case FETCH_USER_GROUPS_SUCCESS: {
      let byId = state.byId;
      Object.keys(action.users).forEach((userId) => {
        byId = {
          ...byId,
          [userId]: {
            ...byId[userId],
            groups: action.users[userId],
          },
        };
      });
      return {
        ...state,
        byId,
      };
    }

    case PUT_AVATAR_SUCCESS:
      if (action.avatar.type === AVATAR_TYPES.USER && action.avatar.id) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.avatar.id]: {
              ...state.byId[action.avatar.id],
              avatar: action.avatar.stored,
            },
          },
        };
      }
      return state;

    case EDIT_PROTEL_NAME_SUCCESS: {
      if (action.data.id) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.data.id]: {
              ...state.byId[action.data.id],
              name: action.data.name,
              fullname: User.getFullname(action.data.name,state.byId[action.data.id].surname)
            },
          },
        };
      }
      return state;
    }

    case SAVE_HOTEL_PROTEL_SYNC_SUCCESS: {
      let byId = state.byId;
      Object.keys(action.data).forEach(item => {
        const found = Object.keys(byId).find(userId => action.data[item].room === byId[userId].protelRoom);
        if (!found) {
          return;
        }
        byId = {
          ...byId,
          [found]: {
            ...byId[found],
            name: found ? action.data[item].name.split(",")[0] : byId[found].name,
            fullname: found ? action.data[item].name.split(",")[0] : byId[found].fullname,
          },
        }
      });
      return {
        ...state,
        byId,
      };
    }

    default:
      return state;
  }
}
